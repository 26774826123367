import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';
import { ThemeContext } from './theme-provider';
import { formatDates } from '../utility';

export default function Calendar({ reference }) {
  const theme = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query CalendarQuery {
      whatsOn: sanityWhatsOn(_id: { regex: "/(drafts\\\\.)?whats-on/" }) {
        programs {
          slug {
            current
          }
          format {
            title
          }
          featureImage {
            ...Image
          }
          people {
            name
          }
          title
          startDate
          endDate
        }
      }
    }
  `)

  return (
    <div
      ref={reference}
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: var(--primary);
        box-shadow: 0px 0px 10rem rgba(0, 0, 0, 0.125);
        cursor: ${(!theme.calendarOpen) ? 'pointer' : 'auto'};
        transform: translateX(${(theme.calendarOpen) ? '0' : 'calc(100% - 1rem)'});
        transition: transform 0.6s var(--curve);
        z-index: 99;

        @media (max-width: 1080px) {
          left: 0;
          padding-top: 5rem;
          transform: translateX(${(theme.calendarOpen) ? '0' : 'calc(-100%)'});
          mix-blend-mode: none;
          transition: transform 0s;
        }
      `}
      onClick={() => { if (!theme.calendarOpen) theme.setCalendarOpen(true) }}
    >
      <div
        className="type--20"
        css={css`
          position: absolute;
          left: -3rem;
          bottom: 5rem;
          transform-origin: 0 100%;
          transform: translateX(0) rotate(90deg) translateX(-100%);

          @media (max-width: 1080px) {
            display: none;
          }
        `}
      >
        <div
          css={css`
            width: 20rem;
            height: 3rem;
            color: ${(theme.calendarOpen) ? 'var(--primary)' : 'var(--black)'};
            text-align: center;
            background-color: var(--primary);
            padding: 0.5rem 1rem;
            transition: color 0.6s var(--curve);
            cursor: pointer;
          `}
          onClick={() => theme.setCalendarOpen(!theme.calendarOpen)}
        >
          What's on
          <div
            css={css`
              position: absolute;
              top: calc(50% - 1rem);
              left: calc(50% - 0.8rem);
              color: var(--black);
              opacity: ${(theme.calendarOpen) ? 1 : 0};
              transform: rotate(-90deg);
              transition: opacity 0.6s var(--curve);
            `}
          >
            → 
          </div>
        </div>
      </div>
      <div
        className="type--45"
        css={css`
          width: 60rem;
          font-weight: 700;
          text-transform: uppercase;
          padding: var(--gutter);
          overflow-y: scroll;

          @media (max-width: 1280px) {
            width: 50rem;
          }

          @media (max-width: 1080px) {
            width: 100%;
          }
        `}
      >
        {data.whatsOn.programs.length > 0 && data.whatsOn.programs
          .sort((a, b) => a.startDate > b.startDate)
          .filter((program) => program.startDate && program.featureImage?.asset)
          .map((program, i) => (
            <div
              key={i}
              css={css`
                margin-bottom: var(--margin);
                
                a {
                  text-decoration: none;
                  transition: color 0.6s var(--curve);

                  &:hover {
                    color: var(--white);
                    transition: color 0s;
                  }
                }
              `}
            >
              <div
                className="type--15"
                css={css`
                  font-weight: normal;
                  text-transform: none;
                  margin-bottom: calc(var(--gutter) * 0.5);

                  &::before {
                    content: '';
                    display: inline-block;
                    width: 1.1rem;
                    height: 1.1rem;
                    border-radius: 50%;
                    background-color: var(--black);
                    margin-right: 1rem;
                  }
                `}
              >{program.format.map(format => format.title).join('/\u200B')}</div>
              <Link
                to={`/program/${program.slug.current}`}
              >
                {`
                  ${(program.people.length > 0) ? `${program.people.map(person => person.name).join(', ')}/\u200b` : ''}
                  ${program.title}/\u200b
                  ${formatDates(program.startDate, program.endDate)}/
                `}
              </Link>
              <Image
                {...program.featureImage}
                width={1980}
                style={{
                  width: '100%',
                  marginTop: 'calc(var(--gutter) * 0.5)',
                }}
                loading="eager"
              />
            </div>
          ))
        }
      </div>
    </div>
  )
}
